<template>
  <v-row align="center" justify="space-between" no-gutters class="footer pl-3 pr-3" v-if="!isMobile">
    <div></div>
    <span class="grey--text footer-text text-justify">Copyright©2021 | Ferdado Engenharia | CNPJ: 25.362.809/0001-94 | I.E: 90846086-34 | Todos os direitos reservados</span>
    <div>
      <span class="grey--text footer-text text-justify">Desenvolvido por:
        <span class="on-hover" @click="goToGithub()">@Adolfok3</span>
      </span>
    </div>
  </v-row>
  <v-row no-gutters align="center" justify="center" class="footer flex-column" v-else>
    <span class="grey--text footer-text text-justify">Copyright©2021 | Ferdado Engenharia</span>
    <span class="grey--text footer-text text-justify">CNPJ: 25.362.809/0001-94 | I.E: 90846086-34</span>
    <span class="grey--text footer-text text-justify">Todos os direitos reservados</span>
    <div>
      <span class="grey--text footer-text text-justify">Desenvolvido por:
        <span class="on-hover" @click="goToGithub()">@Adolfok3</span>
      </span>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    goToGithub () {
      window.open('https://github.com/Adolfok3', '_blank')
    }
  }
}
</script>

<style scoped>
.footer-text {
  font-size: 11px;
  font-family: 'roboto' !important;
}

.footer {
  width: 100%;
  min-height: 30px;
  background: #212121;
}
</style>
