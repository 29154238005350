<template>
  <business-group-base logo="Tertiary.png" color="secondary" title="Business" :parallax="require('Assets/Images/BusinessGroup/Business/Parallax.png')" :description="description" position-image="start start">
    <v-row no-gutters align="center" justify="start">
      <v-col cols="12" sm="12" xl="4" lg="4" md="6" v-for="(item, index) in items" :key="index" class="d-flex justify-center mb-15">
        <business-group-gallery color="secondary" sub-folder="Business" :folder="item.folder" :count="item.count" :title="item.title"></business-group-gallery>
      </v-col>
    </v-row>
  </business-group-base>
</template>

<script>
import BusinessGroupBase from 'Support/Components/Pages/BusinessGroup/Base'
import BusinessGroupGallery from 'Support/Components/Pages/BusinessGroup/BusinessGroupGallery'
import Items from 'Assets/Config/BusinessGroup/Business.js'

export default {
  name: 'Business',
  components: {
    BusinessGroupBase,
    BusinessGroupGallery
  },
  data: () => ({
    items: Items,
    description: 'Através de parcerias, realizamos a manutenção e reforma do seu negócio! Buscamos as soluções que se adéquem dentro da capacidade de orçamento e que revitalizam o imóvel. Com a garantia de manutenção sempre que precisar é só chamar! Entre em contato e vamos elaborar uma proposta para sanar os seus problemas.'
  }),
  mounted () {
    document.title = 'Ferdado Engenharia - Business'
    this.$gtag.screenview({
      app_name: 'Ferdado Engenharia',
      screen_name: this.$route.name
    })
  }
}
</script>

<style scoped>
.cl-title {
  font-size: 18px;
}

.cl-description {
  font-size: 18px;
  font-family: 'ferdado-md' !important;
  letter-spacing: 1px;
  text-align: justify;
}

.lc-div {
  max-width: 300px;
}
</style>
