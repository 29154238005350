<template>
  <v-snackbar dark multi-line top right :color="color" class="elevation-5" v-model="show" :timeout="5000">
    <v-row justify="start" align="center">
      <v-icon large>{{ icon }}</v-icon>
      <div class="ml-4">
        <span class="title-snackbar">{{ title }}</span>
        <br>
        <span class="subtitle-snackbar">{{ subTitle }}</span>
      </div>
    </v-row>
  </v-snackbar>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'AppNotifier',
  created () {
    Vue.prototype.$Notifier = this
  },
  data: () => ({
    show: false,
    icon: 'mdi-check-bold',
    color: 'success',
    title: 'Recebemos sua mensagem!',
    subTitle: 'Entraremos em contato assim que possível.'
  }),
  methods: {
    showSuccess () {
      if (this.show) {
        return
      }

      this.icon = 'mdi-check-bold'
      this.color = 'success'
      this.title = 'Recebemos sua mensagem!'
      this.subTitle = 'Entraremos em contato assim que possível.'
      this.show = true
    },
    showError () {
      if (this.show) {
        return
      }

      this.icon = 'mdi-close-thick'
      this.color = 'error'
      this.title = 'Não foi possível enviar sua mensagem!'
      this.subTitle = 'Por favor, utilize outro meio de contato.'
      this.show = true
    }
  }
}
</script>

<style scoped>
.title-snackbar {
  font-size: 16px !important;
  font-family: 'ferdado' !important;
}

.subtitle-snackbar {
  font-size: 16px !important;
  font-family: 'ferdado-md' !important;
  letter-spacing: 1px;
}
</style>
