<template>
  <v-card :width="cardWith">
    <v-carousel :show-arrows="count > 1" hide-delimiters height="400">
      <v-carousel-item class="on-hover" v-for="(src, i) in count" :key="i" @click="showGallery()">
        <v-img :lazy-src="require(`Assets/Images/BusinessGroup/${subFolder}/${folder}/Lazy/${i}.jpeg`)" :src="require(`Assets/Images/BusinessGroup/${subFolder}/${folder}/${i}.jpeg`)" height="100%"/>
      </v-carousel-item>
    </v-carousel>
    <div class="pa-3">
      <span class="textColor title-bg-gallery">{{ title }}</span>
    </div>
    <v-card-actions v-if="count > 1">
      <v-btn text :color="color" @click="showGallery()">Ver Mais</v-btn>
    </v-card-actions>
    <app-gallery ref="appGallery" :sub-folder="subFolder" :folder="folder" :count="count"></app-gallery>
  </v-card>
</template>

<script>
import AppGallery from 'Support/Components/AppGallery'

export default {
  name: 'BusinessGroupGallery',
  props: {
    title: {
      type: String,
      default: 'Obra Realizada'
    },
    folder: {
      type: String,
      default: '1'
    },
    count: {
      type: Number,
      default: 1
    },
    subFolder: {
      type: String,
      default: 'test'
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    AppGallery
  },
  computed: {
    isXs () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    isLg () {
      return this.$vuetify.breakpoint.name === 'lg'
    },
    cardWith () {
      if (this.isXs) {
        return 350
      }

      if (this.isLg) {
        return 400
      }

      return 450
    }
  },
  methods: {
    showGallery () {
      this.$refs.appGallery.open()
    }
  }
}
</script>

<style scoped>
.title-bg-gallery {
  font-size: 24px;
}
</style>
