import Vue from 'vue'
import App from './App.vue'
import vuetify from 'Support/Plugins/Vuetify'
import router from 'Support/Plugins/Router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { VueMaskDirective } from 'v-mask'
import VuePageTransition from 'vue-page-transition'
import VueGtag from 'vue-gtag'

Vue.use(VuePageTransition)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
})
Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) },
  created () {
    AOS.init()
  }
}).$mount('#app')
