const items = [
  {
    title: 'Academia',
    folder: '1',
    count: 15
  },
  {
    title: 'Brasil Variedades',
    folder: '2',
    count: 4
  },
  {
    title: 'Charms Modas',
    folder: '3',
    count: 4
  },
  {
    title: 'Espaço Comercial',
    folder: '4',
    count: 9
  },
  {
    title: 'Posto Brasil',
    folder: '5',
    count: 7
  },
  {
    title: 'Farmácia Drogamais',
    folder: '6',
    count: 4
  }
]

export default items
