<template>
  <v-card color="white" width="100%" class="elevation-0">
    <v-form ref="form" lazy-validation class="pa-10">
      <v-text-field prepend-inner-icon="mdi-account" placeholder="Nome" label="Nome" v-model="payload.name" :rules="[rules.required]"></v-text-field>
      <v-text-field prepend-inner-icon="mdi-email" placeholder="exemplo@email.com" label="Email" v-model="payload.email" :rules="[rules.required, rules.email]"></v-text-field>
      <v-text-field prepend-inner-icon="mdi-cellphone" placeholder="(DD) 0 0000-0000" label="Telefone" type="text" v-mask="'(##) # ####-####'" v-model="payload.phone" :rules="[rules.required, rules.phone]"></v-text-field>
      <v-textarea prepend-inner-icon="mdi-message" placeholder="Mensagem" label="Mensagem" v-model="payload.message" :rules="[rules.required, rules.message]"></v-textarea>
      <v-btn tile color="success" width="150" :loading="sendingEmail" @click="send()">
        <span class="send-btn-text">Enviar</span>
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import Service from 'Support/Services/ContactService.js'

export default {
  name: 'ContactForm',
  data: () => ({
    sendingEmail: false,
    payload: {
      name: '',
      phone: '',
      email: '',
      message: ''
    },
    rules: {
      required: (v) => {
        return !!v || 'Obrigatório'
      },
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail inválido.'
      },
      phone: (v) => {
        return (v && v.length === 16) || 'Telefone inválido'
      },
      message: (v) => {
        return (v && v.length <= 600) || 'Máximo de 60 caracteres'
      }
    }
  }),
  methods: {
    send () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.sendingEmail = true
      Service.send(this.payload).then(() => {
        this.sendingEmail = false
        this.$refs.form.reset()
        this.$Notifier.showSuccess()
      }).catch(() => {
        this.sendingEmail = false
        this.$refs.form.reset()
        this.$Notifier.showError()
      })
    }
  }
}
</script>

<style scoped>
.send-btn-text {
  font-size: 20px !important;
  font-family: 'ferdado-md' !important;
  letter-spacing: 3px;
}
</style>
