const items = [
  {
    title: 'Edificação Religiosa',
    folder: '1',
    count: 14
  },
  {
    title: 'Edificação Pública',
    folder: '2',
    count: 15
  },
  {
    title: 'Edificação Residencial',
    folder: '3',
    count: 5
  },
  {
    title: 'Edificação Industrial',
    folder: '4',
    count: 1
  },
  {
    title: 'Revitalização de Creche',
    folder: '5',
    count: 7
  }
]

export default items
