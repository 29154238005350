<template>
  <span class="invisibleDiv"></span>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'AppScroll',
  computed: {
    mobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    goToElement (elementName) {
      if (this.$SplashScreen.isLoading) {
        return
      }

      const element = document.getElementById(elementName)
      if (!element) {
        this.$router.push({ path: '/', query: { element: elementName } })
        return
      }

      let scroll = element.getBoundingClientRect().top + window.scrollY
      scroll -= 160
      window.scroll({
        top: scroll,
        behavior: 'smooth'
      })

      if (!this.mobile) {
        return
      }

      this.$AppNavigation.show = false
    }
  },
  created () {
    Vue.prototype.$AppScroll = this
  }
}
</script>

<style scoped>
.invisibleDiv {
  visibility: hidden;
  display: none;
}
</style>
