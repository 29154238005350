<template>
  <v-container fluid :class="{ splashScreen: true, fadeout: !isLoading }">
    <v-row no-gutters class="fill-height" align="center" justify="center">
      <video autoplay playsinline muted id="loadVideo" ref="video" width="250" height="240">
        <source :src="animtedLogo" :type="getMediaType(animtedLogo)">
      </video>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'SplashScreen',
  computed: {
    animtedLogo () {
      return require('Assets/Videos/animated-logo.mp4')
    }
  },
  methods: {
    getMediaType (src) {
      return 'video/' + src.split('.').pop()
    },
    setLoading () {
      const vid = document.getElementById('loadVideo')
      vid.onended = () => {
        this.isLoading = false
        document.documentElement.classList.remove('overflow-y-hidden')
        this.$emit('destroy')
      }
    }
  },
  data: () => ({
    isLoading: true
  }),
  created () {
    Vue.prototype.$SplashScreen = this
  },
  mounted () {
    document.documentElement.classList.add('overflow-y-hidden')
    this.setLoading()
  }
}

window.onbeforeunload = function () {
  window.scrollTo(0, 0)
}
</script>

<style scoped>
.splashScreen {
  background-color: white;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 15;
  height: 100vh;
}

.fadeout {
  animation: fadeout 0.5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
