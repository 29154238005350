const ScrollHandler = {
  name: 'ScrollHandler',
  created () {
    window.addEventListener('scroll', () => {
      this.scroll = document.documentElement.scrollTop
    })
  },
  data: () => ({
    scroll: 0,
    scrollPoint: 350
  })
}

export default ScrollHandler
