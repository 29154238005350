<template>
  <div id="home" class="fullPage">
    <v-carousel :show-arrows="!isMobile" :hide-delimiters="!isMobile" cycle height="100vh" class="homeCarousel">
    <v-carousel-item v-for="(wallpaper, i) in wallpapers" :key="i">
      <v-img :src="wallpaper.src" :lazy-src="wallpaper.lazysrc" class="fullPage"></v-img>
    </v-carousel-item>
    </v-carousel>
    <v-row no-gutters align="center" justify="center" class="fullPage">
      <v-col cols="10" sm="10" md="10" lg="10" xl="10" align-self="center">
        <div class="homeContainer">
          <app-logo home :class="['animated', 'faster', shouldAnimate]"></app-logo>
          <v-divider></v-divider>
          <hr :class="['homeDivider', 'mb-3', 'mt-5']">
          <p class="homeSubTitle white--text" :style="homeSubTitleStyle">{{ description }}</p>
        </div>
        <v-btn color="white" outlined class="mt-5" @click="goToElementContact()">Saiba mais</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppLogo from 'Support/Components/AppLogo'
import ScrollHandler from 'Support/Mixins/ScrollHandler.js'
import PageBase from 'Support/Mixins/PageBase.js'
import HomeTexts from 'Assets/Texts/Home.js'

export default {
  name: 'Home',
  mixins: [PageBase, ScrollHandler],
  components: {
    AppLogo
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    homeTitleStyle () {
      const item = this.textSizes.find(f => f.size === this.$vuetify.breakpoint.name)
      if (!item) {
        return ''
      }

      return `font-size: ${item.title}px`
    },
    homeSubTitleStyle () {
      const item = this.textSizes.find(f => f.size === this.$vuetify.breakpoint.name)
      if (!item) {
        return
      }

      return `font-size: ${item.subTitle}px`
    },
    shouldAnimate () {
      if (this.scroll >= this.scrollPoint) {
        return 'invisible'
      }

      return 'fadeInUpLeft'
    }
  },
  methods: {
    goToElementContact () {
      const element = document.getElementById('contact')
      let scroll = element.getBoundingClientRect().top + window.scrollY
      scroll -= 85
      window.scroll({
        top: scroll,
        behavior: 'smooth'
      })
    }
  },
  data: () => ({
    textSizes: HomeTexts.textSizes,
    wallpapers: HomeTexts.wallpapers,
    description: HomeTexts.description
  }),
  mounted () {
    document.title = 'Ferdado Engenharia - Home'
    this.$gtag.screenview({
      app_name: 'Ferdado Engenharia',
      screen_name: this.$route.name
    })
  }
}
</script>

<style scoped>

.homeCarousel {
  z-index: 0;
  position: absolute;
}

.homeSubTitle {
  line-height: 0.9;
  font-family: 'ferdado-md' !important;
  position: relative;
}

.homeDivider {
  position: relative;
  border: none;
  height: 1px;
  max-width: 600px;
  color: rgb(209, 209, 209);
  background-color: rgb(209, 209, 209);
}

.homeContainer {
  max-width: 700px;
}
</style>
