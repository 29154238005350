<template>
  <v-dialog dark v-model="dialog" content-class="elevation-0" overlay-opacity="0.95">
    <v-row no-gutters align="center" class="fill-height">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="2" class="d-flex justify-center" v-if="showArrows">
          <v-btn icon large @click="prev()">
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xl="8">
          <v-carousel hide-delimiters :show-arrows="isMobile && count > 1" ref="carousel" @change="onChange" :height="isMobile ? undefined : 750">
            <div class="d-flex justify-end">
              <v-icon @click="close()">mdi-close</v-icon>
            </div>
            <v-carousel-item v-for="(src, i) in count" :key="i">
              <v-row justify="center" align="center" class="fill-height">
                <v-img :lazy-src="require(`Assets/Images/BusinessGroup/${subFolder}/${folder}/Lazy/${i}.jpeg`)" :src="require(`Assets/Images/BusinessGroup/${subFolder}/${folder}/${i}.jpeg`)" :max-height="750" :max-width="isMobile ? 300 : 'auto'" contain></v-img>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="2" class="d-flex justify-center" v-if="showArrows">
          <v-btn icon large @click="next()">
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <div class="horizontal-scroll">
      <v-img contain style="display: inline-block;" :lazy-src="require(`Assets/Images/BusinessGroup/${subFolder}/${folder}/Lazy/${i}.jpeg`)" :src="require(`Assets/Images/BusinessGroup/${subFolder}/${folder}/${i}.jpeg`)" max-width="80" max-height="80" :class="['elevation-0', currentIndex === i ? 'selectedImage' : '']" v-for="(src, i) in count" :key="i" @click="change(i)"></v-img>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppGallery',
  props: {
    folder: {
      type: String,
      default: '1'
    },
    count: {
      type: Number,
      default: 1
    },
    subFolder: {
      type: String,
      default: 'test'
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    showArrows () {
      return !this.isMobile && this.count > 1
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    prev () {
      this.$refs.carousel.prev()
    },
    next () {
      this.$refs.carousel.next()
    },
    onChange (index) {
      this.currentIndex = index
    },
    change (index) {
      if (index === this.currentIndex) {
        return
      }

      if (index < this.currentIndex) {
        this.changeDown(index)
        return
      }

      this.changeUp(index)
    },
    changeDown (index) {
      const startIndex = this.currentIndex
      for (let x = startIndex; x > index; x--) {
        this.prev(x)
      }
    },
    changeUp (index) {
      const startIndex = this.currentIndex
      for (let x = startIndex; x < index; x++) {
        this.next(x)
      }
    },
    close () {
      this.dialog = false
    }
  },
  data: () => ({
    dialog: false,
    currentIndex: 0
  })
}
</script>

<style scoped>
.selectedImage {
  border: 3px solid white;
}

.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
}
</style>
