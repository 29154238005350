export default [
  {
    duration: 100,
    initialValue: 0,
    finalValue: 5,
    firstText: 'anos',
    secondText: 'no mercado',
    src: require('Assets/Icons/Chart.png')
  },
  {
    duration: 10,
    initialValue: 0,
    finalValue: 50,
    firstText: null,
    secondText: 'colaboradores',
    src: require('Assets/Icons/Constructor.png')
  },
  {
    duration: 100,
    initialValue: 0,
    finalValue: 5,
    firstText: 'mil',
    secondText: 'm² de construção civil',
    src: require('Assets/Icons/House.png')
  },
  {
    duration: 50,
    initialValue: 0,
    finalValue: 25,
    firstText: 'mil',
    secondText: 'habitantes beneficiados diretamente',
    src: require('Assets/Icons/Employees.png')
  },
  {
    duration: 25,
    initialValue: 0,
    finalValue: 75,
    firstText: 'mil',
    secondText: 'm² de infraestrutura urbana',
    src: require('Assets/Icons/Backhoe.png')
  },
  {
    duration: 25,
    initialValue: 0,
    finalValue: 100,
    firstText: 'km',
    secondText: 'de conservação rodoviária',
    src: require('Assets/Icons/Highway.png')
  }
]
