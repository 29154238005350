<template>
  <v-card tile dark :color="color" :class="['elevation-0', 'd-flex', 'align-center', 'flex-column', 'pa-4']" width="100%" height="250">
    <v-img :src="src" contain width="120" max-height="85"></v-img>
    <p class="aboutIconTitle pb-0 mb-0 mt-5">{{ title }}</p>
    <p class="aboutIconText pb-0 mb-0">{{ text }}</p>
  </v-card>
</template>

<script>
export default {
  name: 'AboutIcon',
  props: {
    src: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    breakPoint () {
      return this.$vuetify.breakpoint.name
    }
  }
}
</script>

<style>
.aboutIconTitle {
  font-size: 32px;
  font-family: 'ferdado' !important;
  text-align: justify;
}

.aboutIconText {
  font-size: 18px;
  font-family: 'ferdado-md' !important;
  text-align: justify;
}
</style>
