<template>
  <div v-if="!mobile">
    <v-btn text rounded :color="dark ? 'white' : 'primary'" :dark="dark" @click="goToElement(btn.element)" v-for="btn in btns" :key="btn.element">{{ btn.text }}</v-btn>
  </div>
  <div v-else>
    <v-list dark>
      <v-list-item v-for="btn in btns" :key="btn.element" link @click="goToElement(btn.element)">
        <v-list-item-icon>
          <v-icon>{{btn.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ btn.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'AppNavigationBtns',
  props: {
    mobile: {
      type: Boolean
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goToElement (elementName) {
      this.$AppScroll.goToElement(elementName)
    }
  },
  data: () => ({
    btns: [
      {
        text: 'Início',
        element: 'home',
        icon: 'mdi-home'
      },
      {
        text: 'Quem somos',
        element: 'about',
        icon: 'mdi-information-variant'
      },
      {
        text: 'Grupos de negócio',
        element: 'businessGroup',
        icon: 'mdi-handshake'
      },
      {
        text: 'Contato',
        element: 'contact',
        icon: 'mdi-phone-outline'
      }
    ]
  })
}
</script>
