<template>
  <v-app v-if="hasMounted">
    <splash-screen v-if="splashScreen" @destroy="destroySplashScreen()"></splash-screen>
    <app-navigation></app-navigation>
    <app-bar></app-bar>
    <vue-page-transition name="fade">
      <router-view></router-view>
    </vue-page-transition>
    <app-footer></app-footer>
    <app-notifier></app-notifier>
    <app-scroll></app-scroll>
  </v-app>
</template>

<script>
import SplashScreen from 'Support/Components/SplashScreen'
import AppFooter from 'Support/Components/AppFooter'
import AppNavigation from 'Support/Components/AppNavigation'
import AppBar from 'Support/Components/AppBar'
import AppScroll from 'Support/Components/AppScroll'
import AppNotifier from 'Support/Components/AppNotifier'

export default {
  name: 'App',
  components: {
    SplashScreen,
    AppNavigation,
    AppBar,
    AppNotifier,
    AppFooter,
    AppScroll
  },
  methods: {
    destroySplashScreen () {
      this.showContent = true
      setTimeout(() => {
        this.splashScreen = false
      }, 1000)
    }
  },
  watch: {
    hasMounted () {
      this.splashScreen = true
    }
  },
  mounted () {
    this.hasMounted = true
  },
  data: () => ({
    splashScreen: false,
    hasMounted: false,
    showContent: false
  })
}
</script>

<style>
@import url('Support/Styles/animate.css');
@import url('Support/Styles/common.css');

@font-face {
  font-family: 'ferdado';
  src: url("Assets/Fonts/ferdado-font.ttf");
}

@font-face {
  font-family: 'ferdado-md';
  src: url("Assets/Fonts/ferdado-md.ttf");
}

body, body *{
   font-family: 'ferdado' !important;
}

.textColor {
  color: #333 !important
}

.appContent {
  background-color: #eee;
}
</style>
