<template>
  <div class="appContent">
    <home @loaded="showAbout = true"></home>
    <about v-if="showAbout" @loaded="showBusinessGroup = true"></about>
    <mission-vision-values v-if="showAbout"></mission-vision-values>
    <business-group v-if="showBusinessGroup" @loaded="showOurNumbers = true"></business-group>
    <our-numbers v-if="showOurNumbers" @loaded="showContact = true"></our-numbers>
    <contact v-if="showContact"></contact>
  </div>
</template>

<script>
import Home from 'App/Home'
import About from 'App/About'
import BusinessGroup from 'App/BusinessGroup'
import Contact from 'App/Contact'
import OurNumbers from 'App/OurNumbers'
import MissionVisionValues from 'App/MissionVisionValues'

export default {
  name: 'AppContent',
  components: {
    Home,
    About,
    BusinessGroup,
    OurNumbers,
    Contact,
    MissionVisionValues
  },
  methods: {
    goToElement () {
      const element = this.$route.query.element
      if (!element) {
        return
      }

      setTimeout(() => {
        this.$AppScroll.goToElement(element)
      }, 250)
    }
  },
  data: () => ({
    showAbout: false,
    showBusinessGroup: false,
    showOurNumbers: false,
    showContact: false
  }),
  created () {
    this.$AppBar.enableAnimation()
    this.$AppBar.setColor('primary')
    this.$AppBar.setLogo(undefined)
    this.$AppBar.setDark(true)
  },
  mounted () {
    this.goToElement()
  }
}
</script>

<style scoped>
.appContent {
  background-color: #eee;
}
</style>
