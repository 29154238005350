<template>
  <business-group-base color="primary" title="Infraestrutura Urbana" :video-mobile="require('Assets/Videos/BusinessGroup/InfraestruturaUrbana/InfraestruturaUrbanaMobile.mp4')" :video="require('Assets/Videos/BusinessGroup/InfraestruturaUrbana/InfraestruturaUrbana.mp4')" :description="description">
    <v-row no-gutters align="center" justify="start">
      <v-col cols="12" sm="12" xl="4" lg="4" md="6" v-for="(item, index) in items" :key="index" class="d-flex justify-center mb-15">
        <business-group-gallery sub-folder="InfraestruturaUrbana" :folder="item.folder" :count="item.count" :title="item.title"></business-group-gallery>
      </v-col>
    </v-row>
  </business-group-base>
</template>

<script>
import BusinessGroupBase from 'Support/Components/Pages/BusinessGroup/Base'
import BusinessGroupGallery from 'Support/Components/Pages/BusinessGroup/BusinessGroupGallery'
import Items from 'Assets/Config/BusinessGroup/InfraestruturaUrbana.js'
import Description from 'Assets/Texts/InfraestruturaUrbana.js'

export default {
  name: 'InfraestruturaUrbana',
  components: {
    BusinessGroupBase,
    BusinessGroupGallery
  },
  data: () => ({
    items: Items,
    description: Description
  }),
  mounted () {
    document.title = 'Ferdado Engenharia - Infraestrutura Urbana'
    this.$gtag.screenview({
      app_name: 'Ferdado Engenharia',
      screen_name: this.$route.name
    })
  }
}
</script>
