<template>
  <page-container full-width id="missionvisionvalues">
    <v-row no-gutters align-content="center" align="center" justify="center">
      <v-col xl="4" lg="4" md="4" cols="12" sm="12" class="mb-0 mt-0" v-for="(icon, index) in icons" :key="index">
        <about-icon :src="icon.src" :title="icon.title" :text="icon.text" :color="icon.color"></about-icon>
      </v-col>
    </v-row>
  </page-container>
</template>

<script>
import PageContainer from 'Support/Components/Pages/PageContainer.vue'
import AboutIcon from 'Support/Components/Pages/About/AboutIcon.vue'
import Icons from 'Assets/Texts/MissionVisionValues.js'

export default {
  name: 'MissionVisionValues',
  components: {
    PageContainer,
    AboutIcon
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  data: () => ({
    icons: Icons
  })
}
</script>
