export default [
  {
    title: 'Infraestrutura Urbana',
    outlined: false,
    src: require('Assets/Icons/icon-dark.png'),
    background: require('Assets/Logos/InfraEstruturaUrbana.png'),
    delay: 0,
    route: 'infraestrutura-urbana'
  },
  {
    title: 'Projetos e Execução',
    outlined: true,
    src: require('Assets/Icons/icon.png'),
    background: require('Assets/Logos/ProjetosEExecucao.png'),
    delay: 300,
    route: 'projetos-execucao'
  },
  {
    title: 'Incorporadora',
    outlined: false,
    src: require('Assets/Icons/icon-dark.png'),
    background: require('Assets/Logos/Incorporadora.png'),
    delay: 600,
    route: 'incorporadora'
  },
  {
    title: 'Business',
    outlined: false,
    background: require('Assets/Logos/Business.png'),
    src: require('Assets/Icons/Tertiary.png'),
    delay: 0,
    route: 'business'
  },
  {
    title: 'Iluminação Pública',
    outlined: false,
    src: require('Assets/Icons/Quaternary.png'),
    background: require('Assets/Logos/IluminacaoPublica.png'),
    delay: 300,
    route: 'iluminacao-publica'
  },
  {
    title: 'Consultoria em Licitações',
    outlined: false,
    src: require('Assets/Icons/Quintenary.png'),
    background: require('Assets/Logos/ConsultoriaEmLicitacoes.png'),
    delay: 600,
    route: 'consultoria-licitacoes'
  }
]
