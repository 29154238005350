export default [
  {
    title: 'Email',
    subTitle: 'contato@ferdadoengenharia.com.br',
    icon: 'mdi-email',
    color: 'orange',
    url: 'mailto:contato@ferdadoengenharia.com.br'
  },
  {
    title: 'Telefone',
    subTitle: '(44) 3346 4782',
    icon: 'mdi-phone',
    color: 'black',
    url: 'tel:4433464782'
  },
  {
    title: 'WhatsApp',
    subTitle: '(41) 9 9852-0155',
    icon: 'mdi-whatsapp',
    color: 'green',
    url: 'https://wa.me/+5541999944703'
  },
  {
    title: 'Facebook',
    subTitle: 'fb.me/ferdadoengenharia',
    icon: 'mdi-facebook',
    color: 'blue',
    url: 'https://www.facebook.com/ferdadoengenharia'
  },
  {
    title: 'Instagram',
    subTitle: '@ferdadoengenharia',
    icon: 'mdi-instagram',
    color: 'pink',
    url: 'https://www.instagram.com/ferdadoengenharia/'
  }
]
