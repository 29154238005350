<template>
  <business-group-base logo="icon-dark.png" color="grey darken-4" title="Incorporadora" :position-image="isMobile ? 'center center' : 'start start'" :parallax="require('Assets/Images/BusinessGroup/Incorporadora/Parallax.png')">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="11" class="mt-10 mb-15">
        <v-row no-gutters align="center" justify="center">
          <v-col xl="6" lg="6" md="6" v-if="!isMobile">
            <v-img contain max-height="200" :src="require('Assets/Images/BusinessGroup/Incorporadora/house1.png')"/>
            <v-img contain max-height="200" :src="require('Assets/Images/BusinessGroup/Incorporadora/house2.png')" class="mt-15"/>
          </v-col>
          <v-col xl="6" lg="6" md="6" cols="12" sm="12">
            <p class="text-justify pageText textColor">{{ description }}</p>
          </v-col>
        </v-row>
        <v-divider class="mt-10"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="space-around">
      <v-col cols="12" sm="5" xl="5" md="5" lg="5" :class="['d-flex', isMobile ? 'justify-center' : 'justify-end']">
        <v-card dark hover color="#185e9c" width="250" height="300" class="pt-5">
          <v-card-title class="justify-center mb-5">
            <v-img contain max-height="50" max-width="200" position="end end" :src="require('Assets/Icons/caixa-icon.png')"/>
          </v-card-title>
          <v-card-text class="white--text text-justify mb-5">
            Realize a simulação do seu financiamento imobiliário através da Caixa Econômica.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn rounded width="150" color="#ef812c" @click="openCaixa()">Simular</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" xl="5" md="5" lg="5" :class="['d-flex', isMobile ? 'justify-center mt-5' : 'justify-start']">
        <v-card dark hover color="#fae100" width="250" height="300" class="pt-5">
          <v-card-title class="justify-center mb-5">
            <v-img contain max-height="50" max-width="200" :src="require('Assets/Icons/banco-brasil-icon.png')"/>
          </v-card-title>
          <v-card-text class="text-justify mb-5">
            <span style="color: #0038a7;">Realize a simulação do seu financiamento imobiliário através do Banco do Brasil.</span>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn rounded width="150" color="#0038a7" @click="openBancoDoBrasil()">Simular</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </business-group-base>
</template>

<script>
import Texts from 'Assets/Texts/Incorporadora.js'
import BusinessGroupBase from 'Support/Components/Pages/BusinessGroup/Base'

export default {
  name: 'Incorporadora',
  components: {
    BusinessGroupBase
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    openCaixa () {
      window.open('http://www8.caixa.gov.br/siopiinternet-web/simulaOperacaoInternet.do?method=inicializarCasoUso', '_blank')
    },
    openBancoDoBrasil () {
      window.open('https://www42.bb.com.br/portalbb/imobiliario/creditoimobiliario/simular,802,2250,2250.bbx?pk_vid=2673c9ab164b999216192012730cca50&pk_vid=2673c9ab164b999216192012730cca50&pk_vid=2673c9ab164b999216192012800cca50', '_blank')
    }
  },
  data: () => ({
    description: Texts
  }),
  mounted () {
    document.title = 'Ferdado Engenharia - Incorporadora'
    this.$gtag.screenview({
      app_name: 'Ferdado Engenharia',
      screen_name: this.$route.name
    })
  }
}
</script>
