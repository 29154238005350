<template>
  <v-container fluid :class="marginTop" :style="style">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'PageContainer',
  props: {
    fullWidth: {
      type: Boolean
    },
    fullHeight: {
      type: Boolean
    },
    marginTop: {
      type: String,
      default: 'pageMargin'
    }
  },
  computed: {
    style () {
      let style = ''
      if (this.fullHeight) {
        style = 'padding-top: 0 !important; padding-bottom: 0 !important;'
      }

      if (!this.fullWidth) {
        return style
      }

      style = style + 'padding-left: 0 !important; padding-right: 0 !important;'

      return style
    }
  }
}
</script>
