<template>
  <page-container full-width full-height class="fullPage" id="contact">
    <v-img :lazy-src="require('Assets/Images/Contact/BackgroundLazy.jpg')" :src="require('Assets/Images/Contact/Background.jpg')" gradient="to top right, #183355ba, #183355ba">
      <v-row no-gutters align="center" justify="center" class="fullPage flex-column mb-5">
        <div>
          <div class="pl-3 pr-3">
            <p class="pageTitle white--text text-center">Fale Conosco</p>
            <p class="pageSubTitle white--text text-justify text-center">Sinta-se a vontade para falar conosco através de um dos nossos meios de contato ou se preferir, preencha o formulário e entraremos em contato assim que possível.</p>
          </div>
          <v-row no-gutters align="center" justify="center" class="mt-15">
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" :class="['d-flex', isMobile ? 'justify-center' : 'justify-end']">
              <div class="d-flex justify-center align-start flex-column" :style="contactItemsStyle">
                <contact-item :title="item.title" :sub-title="item.subTitle" :icon="item.icon" :color="item.color" :url="item.url" :last-item="index ===(items.length - 1)" v-for="(item, index) in  items" :key="index"></contact-item>
              </div>
            </v-col>
            <v-col xl="5" lg="5" md="5" sm="8" cols="11" :class="['d-flex', isMobile ? 'justify-center mt-10' : 'justify-start']">
              <contact-form></contact-form>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-img>
  </page-container>
</template>

<script>
import PageContainer from 'Support/Components/Pages/PageContainer.vue'
import ContactForm from 'Support/Components/Pages/Contact/ContactForm.vue'
import ContactItem from 'Support/Components/Pages/Contact/ContactItem.vue'
import PageBase from 'Support/Mixins/PageBase.js'
import ContactItems from 'Assets/Config/Contact/ContactItems.js'

export default {
  name: 'Contact',
  mixins: [PageBase],
  components: {
    PageContainer,
    ContactForm,
    ContactItem
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    contactItemsStyle () {
      const width = this.contactItemsSizes.find(f => f.breakpoint === this.breakpoint).width
      const response = `min-width: ${width};`

      return response
    }
  },
  data: () => ({
    items: ContactItems,
    contactItemsSizes: [
      {
        breakpoint: 'xl',
        width: '500px'
      },
      {
        breakpoint: 'lg',
        width: '500px'
      },
      {
        breakpoint: 'md',
        width: '400px'
      },
      {
        breakpoint: 'sm',
        width: 'auto'
      },
      {
        breakpoint: 'xs',
        width: 'auto'
      }
    ]
  })
}
</script>
