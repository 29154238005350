<template>
  <v-layout row :justify-start="!navigation" :justify-center="navigation" align-center :class="[logoClass]" @click="goToHome()">
    <v-img :max-width="maxWidth" :src="requiredLogo"></v-img>
    <div :class="['companyLogo', 'ml-4']">
      <v-layout justify-center align-center column>
        <span :class="[dark ? 'white--text' : 'primary--text', 'appLogoText']" :style="titleStyle">FERDADO</span>
        <span :class="[dark ? 'white--text' : 'primary--text', 'appLogoText', 'companySubTitle']" :style="subTitleStyle">ENGENHARIA</span>
      </v-layout>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    navigation: {
      type: Boolean
    },
    mobile: {
      type: Boolean
    },
    home: {
      type: Boolean
    },
    dark: {
      type: Boolean,
      default: true
    },
    logo: {
      type: String,
      default: 'icon-dark.png'
    }
  },
  computed: {
    requiredLogo () {
      return require(`Assets/Icons/${this.logo}`)
    },
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    maxWidth () {
      if (this.home) {
        const size = this.sizes.find(f => f.breakpoint === this.breakpoint)
        return size.imageWidth
      }

      return 45
    },
    logoClass () {
      if (this.mobile) {
        return ''
      }

      if (!this.navigation) {
        if (this.home) {
          return 'ml-2'
        }

        return 'ml-5 on-hover'
      }

      return ''
    },
    titleStyle () {
      if (this.home) {
        const size = this.sizes.find(f => f.breakpoint === this.breakpoint)
        return size.title
      }

      return 'font-size: 24px;'
    },
    subTitleStyle () {
      if (this.home) {
        const size = this.sizes.find(f => f.breakpoint === this.breakpoint)
        return size.subTitle
      }

      return 'font-size: 10px;'
    }
  },
  methods: {
    goToHome () {
      if (this.home) {
        return
      }

      this.$AppScroll.goToElement('home')
    }
  },
  data: () => ({
    sizes: [
      {
        title: 'font-size: 42px; margin-left: 90px;',
        subTitle: 'font-size: 16px; margin-left: 78px; margin-top: -21px;',
        breakpoint: 'xs',
        imageWidth: 60
      },
      {
        title: 'font-size: 52px; margin-left: 120px;',
        subTitle: 'font-size: 19px; margin-left: 100px; margin-top: -23px;',
        breakpoint: 'sm',
        imageWidth: 70
      },
      {
        title: 'font-size: 58px; margin-left: 140px;',
        subTitle: 'font-size: 22px; margin-left: 120px; margin-top: -28px;',
        breakpoint: 'md',
        imageWidth: 90
      },
      {
        title: 'font-size: 64px; margin-left: 180px;',
        subTitle: 'font-size: 24px; margin-left: 155px; margin-top: -30px;',
        breakpoint: 'lg',
        imageWidth: 110
      },
      {
        title: 'font-size: 70px; margin-left: 200px;',
        subTitle: 'font-size: 28px; margin-left: 180px; margin-top: -35px;',
        breakpoint: 'xl',
        imageWidth: 130
      }
    ]
  })
}
</script>

<style scoped>
.companyLogo {
  max-width: 150px;
}

.appLogoText {
  letter-spacing: 3px !important;
}

.companySubTitle {
  margin-top: -10px;
  margin-left: -4px;
}
</style>
