<template>
  <page-container full-width id="ourNumbers">
    <v-parallax dark :src="require('Assets/Wallpapers/Parallax.jpg')" id="ourNumberContainer" :height="isMobile ? '750' : 500">
      <p :class="['pageTitle', 'text-center', isMobile ? 'mt-5' : 'mt-15']">Nossos Números</p>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="5" xl="2" lg="2" md="4" sm="4" v-for="(number, index) in numbers" :key="index">
          <number-count ref="numbers" :final-value="number.finalValue" :first-text="number.firstText" :second-text="number.secondText" :src="number.src" :initial-value="number.initialValue" :duration="number.duration"></number-count>
        </v-col>
      </v-row>
    </v-parallax>
  </page-container>
</template>

<script>
import Numbers from 'Assets/Config/OurNumbers/Numbers.js'
import NumberCount from 'Support/Components/Pages/OurNumbers/NumberCount.vue'
import PageContainer from 'Support/Components/Pages/PageContainer.vue'
import PageBase from 'Support/Mixins/PageBase.js'
import ScrollHandler from 'Support/Mixins/ScrollHandler.js'

export default {
  name: 'OurNumbers',
  mixins: [PageBase, ScrollHandler],
  components: {
    PageContainer,
    NumberCount
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  watch: {
    scroll () {
      const breakpoint = this.ourNumberContainerPosition - 750
      if (this.scroll >= breakpoint) {
        this.startCount()
      }
    }
  },
  methods: {
    startCount () {
      for (let index = 0; index < this.numbers.length; index++) {
        this.$refs.numbers[index].start()
      }
    }
  },
  data: () => ({
    ourNumberContainerPosition: 0,
    numbers: Numbers
  }),
  mounted () {
    const offsets = document.getElementById('ourNumberContainer').getBoundingClientRect()
    this.ourNumberContainerPosition = parseInt(offsets.top)
  }
}
</script>
