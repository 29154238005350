const items = [
  {
    title: 'Captação de Editais',
    description: 'Captamos editais diariamente, basta definir o seu segmento e região desejada, e o resto, é por nossa conta!',
    icon: 'mdi-script-text',
    justify: 'justify-end'
  },
  {
    title: 'Análise Técnica dos Editais',
    description: 'Disponibilizamos uma equipe técnica para análise completa dos editais, apontando irregularidades e vícios que geram prejuízos.',
    icon: 'mdi-text-box-search',
    justify: 'justify-center'
  },
  {
    title: 'Gestão Documental',
    description: 'Realizamos a gestão dos documentos, controlando as certidões, emitindo documento e anexos e orientação para obtenção dos mesmos.',
    icon: 'mdi-folder-open',
    justify: 'justify-start'
  },
  {
    title: 'Montagem de Documentação',
    description: 'De maneira minuciosa, montamos os envelopes de acordo com cada edital bem como a elaboração da proposta de preços quando for o caso.',
    icon: 'mdi-file-document-multiple',
    justify: 'justify-end'
  },
  {
    title: 'Representação Presencial',
    description: 'Representamos sua Empresa nos certames presenciais, através de uma equipe qualificada, sempre atenta na documentação apresentada pelos concorrentes.',
    icon: 'mdi-handshake',
    justify: 'justify-center'
  },
  {
    title: 'Cadastramento em Portais e Órgãos',
    description: 'Efetuamos o cadastro de sua empresa junto aos Órgãos Públicos, e Portais de vendas Públicas, cadastrando toda documentação relacionada a habilitação jurídica, fiscal, econômica e técnica.',
    icon: 'mdi-card-account-details',
    justify: 'justify-start'
  },
  {
    title: 'Pregões Eletrônicos',
    description: 'Além dos pregões presenciais, concorremos também em pregões eletrônicos em todas as etapas, monitoramos todas as etapas do processos nos diversos portais existentes.',
    icon: 'mdi-desktop-mac-dashboard',
    justify: 'justify-center'
  },
  {
    title: 'Jurídico Especializado',
    description: 'Possuímos equipe de Advogados especializados em Licitações e Contratos, responsáveis pelo contencioso administrativo e jurídico.',
    icon: 'mdi-gavel',
    justify: 'justify-center'
  }
]

export default items
