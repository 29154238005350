<template>
  <business-group-base logo="Quintenary.png" color="grey darken-4" title="Consultoria em Licitações" :parallax="require('Assets/Images/BusinessGroup/ConsultoriaLicitacoes/Parallax.png')" :description="description" :text-cols="textCols">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" xl="4" lg="4" md="4" sm="6" v-for="(item, i) in items" :key="i" :class="['d-flex', isMobile ? 'justify-center' : item.justify, 'mb-15']">
        <div class="lc-div d-flex flex-column justify-center align-center">
          <v-icon size="75" color="grey darken-4" class="mb-3">{{ item.icon }}</v-icon>
          <span class="cl-title text-justify textColor">{{ item.title }}</span>
          <span class="cl-description textColor">{{ item.description }}</span>
        </div>
      </v-col>
    </v-row>
  </business-group-base>
</template>

<script>
import BusinessGroupBase from 'Support/Components/Pages/BusinessGroup/Base'
import Items from 'Assets/Config/BusinessGroup/ConsultoriaLicitacoes.js'
import Description from 'Assets/Texts/ConsultoriaLicitacoes.js'

export default {
  name: 'ConsultoriaLicitacoes',
  components: {
    BusinessGroupBase
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    textCols () {
      const breakpoint = this.breakpoint
      switch (breakpoint) {
        case 'xl':
          return '8'
        case 'lg':
          return '9'
        case 'md':
          return '10'
        case 'sm':
          return '11'
        case 'xs':
          return '11'
        default:
          return '11'
      }
    }
  },
  data: () => ({
    items: Items,
    description: Description
  }),
  mounted () {
    document.title = 'Ferdado Engenharia - Consultoria e Licitações'
    this.$gtag.screenview({
      app_name: 'Ferdado Engenharia',
      screen_name: this.$route.name
    })
  }
}
</script>

<style scoped>
.cl-title {
  font-size: 18px;
}

.cl-description {
  font-size: 18px;
  font-family: 'ferdado-md' !important;
  letter-spacing: 1px;
  text-align: justify;
}

.lc-div {
  max-width: 300px;
}
</style>
