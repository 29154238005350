<template>
  <div class="appContent">
    <v-img dark :src="parallax" :height="500" :position="positionImage" v-if="parallax">
      <v-row align="center" justify="center" class="fill-height">
        <v-col class="text-center" cols="12">
          <p class="title-bg">{{ title }}</p>
        </v-col>
      </v-row>
    </v-img>
    <video-background v-else :src="videoSrc" style="max-height: 500px; height: 100vh;" overlay="rgba(0, 0, 0, 0.500)">
      <v-row align="center" justify="center" class="fill-height">
        <v-col class="text-center" cols="12">
          <p class="title-bg">{{ title }}</p>
        </v-col>
      </v-row>
    </video-background>
    <v-row no-gutters justify="center" align="center" class="mt-10 mb-15" v-if="description">
      <v-col :cols="textCols">
        <p class="text-justify pageText textColor">{{ description }}</p>
        <v-divider class="mt-10"></v-divider>
      </v-col>
    </v-row>
    <slot></slot>
    <contact></contact>
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import Contact from 'App/Contact'

export default {
  name: 'BusinessGroupBase',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: 'Title'
    },
    description: {
      type: String,
      default: undefined
    },
    parallax: {
      type: String,
      default: undefined
    },
    video: {
      type: String,
      default: undefined
    },
    videoMobile: {
      type: String,
      default: undefined
    },
    light: {
      type: Boolean
    },
    logo: {
      type: String,
      default: undefined
    },
    textCols: {
      type: String,
      default: '11'
    },
    positionImage: {
      type: String,
      default: 'center center'
    }
  },
  components: {
    Contact,
    VideoBackground
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    parallaxHeight () {
      return this.isMobile ? 500 : 500
    },
    videoSrc () {
      return this.isMobile ? this.videoMobile : this.video
    }
  },
  created () {
    this.$AppBar.disableAnimation()
    this.$AppBar.setColor(this.color)
    this.$AppBar.setLogo(this.logo)
    this.$AppBar.setDark(!this.light)
  }
}
</script>

<style scoped>
.appContent {
  background-color: #eee !important;
}

.ablbule {
  background-color: rgba(0, 0, 0, 0.200) !important;
}
</style>
