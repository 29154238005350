<template>
  <v-app-bar app height="80" :color="appBarColor" :class="[appBarElevation]">
    <div :class="[animate ? 'animated' : '', 'faster', animation]">
      <app-logo :logo="logo" :mobile="isMobile" :dark="dark"></app-logo>
    </div>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon :color="dark ? 'white' : 'primary'" :dark="dark" v-if="isMobile" @click="openAppNavigation()"></v-app-bar-nav-icon>
    <app-navigation-btns :dark="dark" v-else></app-navigation-btns>
  </v-app-bar>
</template>

<script>
import Vue from 'vue'
import AppNavigationBtns from 'Support/Components/AppNavigationBtns'
import AppLogo from 'Support/Components/AppLogo'
import ScrollHandler from 'Support/Mixins/ScrollHandler.js'

export default {
  name: 'AppBar',
  mixins: [ScrollHandler],
  components: {
    AppNavigationBtns,
    AppLogo
  },
  computed: {
    appBarColor () {
      return this.scroll >= this.scrollPoint ? this.color : 'transparent'
    },
    appBarElevation () {
      return this.scroll >= this.scrollPoint ? 'v-app-bar--elevate-on-scroll' : 'elevation-0'
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    animation () {
      if (this.scroll >= this.scrollPoint) {
        return 'fadeInDownRight'
      }

      return this.animate ? 'invisible' : ''
    }
  },
  methods: {
    openAppNavigation () {
      this.$AppNavigation.show = true
    },
    disableAnimation () {
      this.animate = false
    },
    enableAnimation () {
      this.animate = true
    },
    setColor (color) {
      this.color = color
    },
    setDark (dark) {
      this.dark = dark
    },
    setLogo (logo) {
      this.logo = logo
    }
  },
  data: () => ({
    animate: true,
    color: 'primary',
    logo: 'icon-dark.png',
    dark: true
  }),
  created () {
    Vue.prototype.$AppBar = this
  }
}
</script>
