const icons = [
  {
    src: require('Assets/Icons/Mission.png'),
    title: 'Missão',
    text: 'Executar os projetos com qualidade e eficácia.'
  },
  {
    src: require('Assets/Icons/Vision.png'),
    title: 'Visão',
    text: 'Fazer parte do crescimento socioeconômico do país investindo em soluções integradas de engenharia. Atendendo de maneira inteligente as demandas de nossos clientes.',
    color: 'secondary'
  },
  {
    src: require('Assets/Icons/Values.png'),
    title: 'Valores',
    text: 'Resiliência, transparência e responsabilidade.'
  }
]

export default icons
