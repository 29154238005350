<template>
  <v-card tile dark color="transparent" class="text-center elevation-0">
    <v-img :src="src" contain :aspect-ratio="16/9" width="180" class="img-center"></v-img>
    <p :class="['mb-0', isMobile ? 'smallTextMobile' : 'smallText']">+ DE
      <span :class="['ml-2', 'mb-0', isMobile ? 'numberCountTextMobile' : 'numberCountText']">{{ value.toLocaleString() }}</span>
      <span :class="['ml-2', 'mb-0', isMobile ? 'smallTextMobile' : 'smallText']" v-if="firstText">{{ firstText.toUpperCase() }}</span>
    </p>
    <div class="smallTextContent d-flex justify-center">
      <div class="largeTextContent mt-0">
        <p class="text-center mt-0">{{ secondText.toUpperCase() }}</p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'NumberCount',
  props: {
    duration: {
      type: Number,
      default: 0
    },
    initialValue: {
      type: Number,
      default: 0
    },
    finalValue: {
      type: Number,
      default: 0
    },
    src: {
      type: String,
      default: null
    },
    firstText: {
      type: String,
      default: ''
    },
    secondText: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.lgAndDown
    }
  },
  methods: {
    start () {
      if (this.isFinished) {
        return
      }
      this.isFinished = true
      this.interval = setInterval(() => {
        this.value += 1
        if (this.value >= this.finalValue) {
          this.stop()
        }
      }, this.duration)
    },
    stop () {
      clearInterval(this.interval)
    }
  },
  data: () => ({
    value: 0,
    interval: null,
    isFinished: false
  }),
  mounted () {
    this.value = this.initialValue
  }
}
</script>

<style scoped>
.numberCountText {
  font-size: 68px !important;
}

.numberCountTextMobile {
  font-size: 44px !important;
}

.smallText {
  font-size: 28px;
}

.smallTextMobile {
  font-size: 16px;
}

.largeTextContent {
  max-width: 200px;
}

.smallTextContent {
  width: 100%;
  margin-top: -15px !important;
}
</style>
