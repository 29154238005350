<template>
  <page-container id="about">
    <v-row no-gutters align-content="center" align="center" justify="space-around">
      <v-col cols="11" sm="11" md="5" lg="5" xl="5" data-aos="fade-right" :data-aos-once="true">
        <p class="pageTitle textColor text-center">{{ title }}</p>
        <p class="text-justify pageText textColor" v-html="content"></p>
        <div v-if="isMobile">
          <v-carousel hide-delimiters cycle height="350" class="mt-2 mb-2">
            <v-carousel-item v-for="(image, i) in mobileImages" :key="i" :src="image.src"></v-carousel-item>
          </v-carousel>
          <p class="text-justify pageText textColor">{{ secondContent }}</p>
        </div>
      </v-col>
      <v-col md="5" lg="5" xl="5" v-if="!isMobile" class="d-flex justify-center pageMargin">
        <v-row align="center" justify="center">
          <about-image v-for="(item, index) in images" :key="index + 1" :index="index + 1" :data-aos-delay="item.dataAosDelay" :max-width="item.maxWidth" :max-height="item.maxHeight" :elevation="item.elevation" :stretch="item.stretch" :src="item.src" :lazy-src="item.lazySrc" :disable-hover="item.disableHover"></about-image>
        </v-row>
      </v-col>
    </v-row>
  </page-container>
</template>

<script>
import PageContainer from 'Support/Components/Pages/PageContainer.vue'
import PageBase from 'Support/Mixins/PageBase.js'
import AboutTexts from 'Assets/Texts/About.js'
import AboutImage from 'Support/Components/Pages/About/AboutImage.vue'

export default {
  name: 'About',
  mixins: [PageBase],
  components: {
    PageContainer,
    AboutImage
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    content () {
      if (this.isMobile) {
        return this.firstContent
      }

      return this.firstContent + this.secondContent
    },
    mobileImages () {
      const images = this.images.slice(0)

      return images.splice(0, 2)
    }
  },
  data: () => ({
    title: AboutTexts.title,
    firstContent: AboutTexts.firstContent,
    secondContent: AboutTexts.secondContent,
    images: [
      {
        dataAosDelay: 600,
        elevation: true,
        stretch: '+',
        src: require('Assets/Images/About/1.jpg'),
        lazySrc: require('Assets/Images/About/Lazy/1.jpg')
      },
      {
        dataAosDelay: 200,
        elevation: true,
        stretch: '-',
        src: require('Assets/Images/About/2.jpg'),
        lazySrc: require('Assets/Images/About/Lazy/2.jpg')
      },
      {
        dataAosDelay: 1000,
        maxWidth: 100,
        maxHeight: 150,
        elevation: false,
        src: require('Assets/Images/About/3.jpg'),
        disableHover: true
      }
    ]
  })
}
</script>

<style>
@import url('../../Support/Styles/About/AboutImage.css');

.iconDivider {
  width: 3px;
  background-color: #d3d3d3;
  height: 30vh;
  border-radius: 10px;
}
</style>
