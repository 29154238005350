<template>
  <v-hover v-slot="{ hover }">
    <v-card hover rounded="lg" color="white" height="200" width="400" @click.native="goToRoute()">
      <v-card-title class="justify-center">
        <v-card :img="background" height="150" width="99%" rounded="lg" :class="['elevation-0', 'insideCard', 'elevation-1', 'animated', 'veryFaster', outlined ? 'outlinedCard' : '', hover ? 'bounceOutUp' : 'bounceInDown']">
          <v-row no-gutters align="center" justify="start" class="fill-height">
            <v-img contain max-width="70px" :src="src" class="ml-3"></v-img>
          </v-row>
        </v-card>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-btn small text color="primary" class="elevation-0 moreDetails" v-bind="attrs" v-on="on">
            <v-icon small dark>mdi-plus</v-icon>
            Detalhes
          </v-btn>
          </template>
          <span>Em breve</span>
        </v-tooltip>
      </v-card-title>
      <v-card-actions class="justify-center">
        <p class="bgTitle">{{ title }}</p>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'BusinessGroupCard',
  props: {
    title: {
      type: String,
      default: 'Grupo de Negócio'
    },
    src: {
      type: String
    },
    outlined: {
      type: Boolean
    },
    background: {
      type: String,
      default: null
    },
    route: {
      type: String,
      default: 'Test'
    }
  },
  computed: {
    gradientStyle () {
      return 'background: rgb(2,0,36); background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(24,51,85,1) 16%, rgba(24,51,85,0) 100%); object-fit: cover;'
    }
  },
  methods: {
    goToRoute () {
      this.$router.push(this.route)
    }
  }
}
</script>

<style scoped>
.insideCard {
  margin: -35px 0px 0;
  z-index: 1;
}

.bgTitle {
  font-size: 26px;
  font-family: 'ferdado-md' !important;
}

.moreDetails {
  margin: -35px 0px 0;
}

.outlinedCard {
  border: 1px solid #183355 !important;
}
</style>
