<template>
  <page-container id="businessGroup">
    <v-card color="transparent" elevation="10" class="elevation-0">
      <v-card-title class="justify-center">
        <div class="d-flex justify-center align-center flex-column">
          <p class="pageTitle">Grupos de Negócio</p>
          <p class="pageSubTitle text-center">A Ferdado possui experiência em diversas áreas para atender diferentes tipos de trabalhos e negócios.</p>
        </div>
      </v-card-title>
      <v-card-text class="mb-5">
        <v-row align="center" justify="center">
          <v-col cols="12" xl="4" lg="4" md="6" sm="6" class="d-flex justify-center mt-15" data-aos="fade-up" data-aos-duration="300" :data-aos-once="true" v-for="(card, index) in cards" :key="index" :data-aos-delay="card.delay">
            <bg-card :background="card.background" :title="card.title" :src="card.src" :outlined="card.outlined" :route="card.route"></bg-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </page-container>
</template>

<script>
import PageContainer from 'Support/Components/Pages/PageContainer.vue'
import PageBase from 'Support/Mixins/PageBase.js'
import Cards from 'Assets/Config/BusinessGroup/Cards.js'
import BgCard from 'Support/Components/Pages/BusinessGroup/BusinessGroupCard.vue'

export default {
  name: 'BusinessGroup',
  mixins: [PageBase],
  components: {
    PageContainer,
    BgCard
  },
  data: () => ({
    cards: Cards
  })
}
</script>
