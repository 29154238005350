const textSizes = [
  {
    size: 'xl',
    title: '100',
    subTitle: '24'
  },
  {
    size: 'lg',
    title: '84',
    subTitle: '24'
  },
  {
    size: 'md',
    title: '64',
    subTitle: '22'
  },
  {
    size: 'sm',
    title: '50',
    subTitle: '22'
  },
  {
    size: 'xs',
    title: '40',
    subTitle: '20'
  }
]

const wallpapers = [
  {
    src: require('Assets/Wallpapers/3.jpg'),
    lazysrc: require('Assets/Wallpapers/Lazy/3.jpg')
  },
  {
    src: require('Assets/Wallpapers/1.jpg'),
    lazysrc: require('Assets/Wallpapers/Lazy/1.jpg')
  },
  {
    src: require('Assets/Wallpapers/2.jpg'),
    lazysrc: require('Assets/Wallpapers/Lazy/2.jpg')
  }
]

const description = 'Comprometimento com a qualidade.'

export default { textSizes, wallpapers, description }
