<template>
  <business-group-base logo="Quintenary.png" color="#8f9091" title="Iluminação Pública" :parallax="require('Assets/Images/BusinessGroup/IluminacaoPublica/Parallax.png')" :description="description">
    <v-row no-gutters align="center" justify="center">
      <span>Em breve!</span>
    </v-row>
  </business-group-base>
</template>

<script>
import BusinessGroupBase from 'Support/Components/Pages/BusinessGroup/Base'

export default {
  name: 'IluminacaoPublica',
  components: {
    BusinessGroupBase
  },
  data: () => ({
    description: 'A empresa conta com um setor voltado para instalação de Luminárias Públicas em LED, contendo profissionais com expertise e qualidade. Devidamente registrada no CREA-PR, a Ferdado Engenharia conta com equipe capacitada, seguindo todas as normas e requisitos exigidos para a execução do serviço, além de cadastro junto a COPEL (Companhia Paranaense de Energia) e atestados de capacidade técnica de vários municípios. Ao realizar a instalação de iluminação em LED a empresa oferece diversos aspectos benéficos de segurança, economia para os municípios e também contribui com o meio ambiente.'
  }),
  mounted () {
    document.title = 'Ferdado Engenharia - Iluminação Pública'
    this.$gtag.screenview({
      app_name: 'Ferdado Engenharia',
      screen_name: this.$route.name
    })
  }
}
</script>
