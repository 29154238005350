import Vue from 'vue'
import VueRouter from 'vue-router'
import AppContent from 'Support/Components/AppContent'
import InfraestruturaUrbana from 'App/BusinessGroup/InfraestruturaUrbana.vue'
import ProjetosExecucao from 'App/BusinessGroup/ProjetosExecucao.vue'
import ConsultoriaLicitacoes from 'App/BusinessGroup/ConsultoriaLicitacoes.vue'
import Business from 'App/BusinessGroup/Business.vue'
import Incorporadora from 'App/BusinessGroup/Incorporadora.vue'
import IluminacaoPublica from 'App/BusinessGroup/IluminacaoPublica.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: AppContent
  },
  {
    path: '/infraestrutura-urbana',
    name: 'infraestrutura-urbana',
    component: InfraestruturaUrbana
  },
  {
    path: '/projetos-execucao',
    name: 'projetos-execucao',
    component: ProjetosExecucao
  },
  {
    path: '/consultoria-licitacoes',
    name: 'consultoria-licitacoes',
    component: ConsultoriaLicitacoes
  },
  {
    path: '/business',
    name: 'business',
    component: Business
  },
  {
    path: '/incorporadora',
    name: 'incorporadora',
    component: Incorporadora
  },
  {
    path: '/iluminacao-publica',
    name: 'iluminacao-publica',
    component: IluminacaoPublica
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
