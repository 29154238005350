<template>
  <v-tooltip bottom :disabled="!url">
    <template v-slot:activator="{ on, attrs }">
      <v-card dark tile :class="['elevation-0', lastItem ? 'mb-0' : 'mb-15']" color="transparent" :hover="!!url" @click.native="goToUrl()" v-on="on" v-bind="attrs">
        <v-row justify="start" align="center">
          <v-icon size="30" class="circle-icon" :color="color">{{ icon }}</v-icon>
          <div class="ml-4 d-flex justify-center align-start flex-column">
            <span class="title-contact-item">{{ title }}</span>
            <span class="subtitle-contact-item">{{ subTitle }}</span>
          </div>
        </v-row>
      </v-card>
    </template>
    <span>Abrir no {{title}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ContactItem',
  props: {
    title: {
      type: String,
      default: 'Email'
    },
    subTitle: {
      type: String,
      default: 'exemplo@email.com'
    },
    icon: {
      type: String,
      default: 'mdi-email'
    },
    color: {
      type: String,
      default: 'black'
    },
    url: {
      type: String,
      default: undefined
    },
    lastItem: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToUrl () {
      if (!this.url) {
        return
      }

      window.open(this.url, '_blank')
    }
  }
}
</script>

<style scoped>
.subtitle-contact-item {
  font-family: 'ferdado-md' !important;
  font-size: 18px;
  letter-spacing: 1px;
}

.title-contact-item {
  font-size: 20px;
}

.circle-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: white;
}
</style>
