<template>
  <v-navigation-drawer app right temporary v-model="show" class="primary">
    <v-layout justify-start align-center column fill-height>
      <v-card class="elevation-0 transparent" width="100%" height="150">
        <v-layout justify-center align-center row fill-height>
          <app-logo navigation></app-logo>
        </v-layout>
        <v-divider class="dividerDarken"></v-divider>
      </v-card>
      <app-navigation-btns mobile></app-navigation-btns>
      <v-spacer></v-spacer>
      <span class="appVerion white--text">Versão 0.2.0</span>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import AppLogo from 'Support/Components/AppLogo'
import AppNavigationBtns from 'Support/Components/AppNavigationBtns'

export default {
  name: 'AppNavigation',
  components: {
    AppNavigationBtns,
    AppLogo
  },
  data: () => ({
    show: false
  }),
  created () {
    Vue.prototype.$AppNavigation = this
  }
}
</script>

<style scoped>
.dividerDarken {
  background-color: rgb(119, 119, 119) !important;
}

.appVerion {
  font-family: 'ferdado-md' !important;
}
</style>
