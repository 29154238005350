<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <div :class="['absolute', `image${index}`, hover && !disableHover ? 'hoverClass' : '']" :style="imageStyle">
        <v-card hover :max-width="hover && !disableHover ? 550 : width" :max-height="height" color="transparent" :class="[hover && !disableHover ? 'elevation-24' : elevationComputed, `imageAnimation${index}`, 'transition-swing']">
          <v-img :id="`AboutImage${index}`" :max-width="hover && !disableHover ? 550 : width" :max-height="height" :lazy-src="lazySrc" :src="src" data-aos="flip-right" data-aos-duration="1000" :data-aos-delay="dataAosDelay" :data-aos-once="true"></v-img>
        </v-card>
      </div>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: 'AboutImage',
  props: {
    index: {
      type: Number || String,
      default: 1
    },
    dataAosDelay: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number || String,
      default: 350
    },
    maxHeight: {
      type: Number || String,
      default: 350
    },
    elevation: {
      type: Boolean
    },
    stretch: {
      type: String,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    lazySrc: {
      type: String,
      default: null
    },
    disableHover: {
      type: Boolean
    }
  },
  computed: {
    breakPoint () {
      return this.$vuetify.breakpoint.name
    },
    width () {
      const size = this.imagesSizes.find(f => f.breakPoint === this.breakPoint)
      if (!size || size.height > this.maxWidth) {
        return this.maxWidth
      }

      return size.width
    },
    height () {
      const size = this.imagesSizes.find(f => f.breakPoint === this.breakPoint)
      if (!size || size.height > this.maxHeight) {
        return this.maxHeight
      }

      return size.height
    },
    elevationComputed () {
      if (!this.setElevation) {
        return 'elevation-0'
      }

      return 'elevation-10'
    },
    imageStyle () {
      if (!this.stretch) {
        return ''
      }

      switch (this.breakPoint) {
        case 'xl': return `margin-left: ${this.stretch}350px !important;`
        case 'lg': return `margin-left: ${this.stretch}300px !important;`
        case 'md': return `margin-left: ${this.stretch}250px !important;`
      }

      return ''
    }
  },
  data: () => ({
    setElevation: false,
    imagesSizes: [
      {
        breakPoint: 'xl',
        width: 350,
        height: 350
      },
      {
        breakPoint: 'lg',
        width: 300,
        height: 300
      },
      {
        breakPoint: 'md',
        width: 250,
        height: 250
      }
    ]
  }),
  mounted () {
    if (!this.elevation) {
      return
    }

    document.addEventListener('aos:in', ({ detail }) => {
      if (detail.id === `AboutImage${this.index}`) {
        setTimeout(() => {
          this.setElevation = true
        }, this.dataAosDelay + 700)
      }
    })
  }
}
</script>

<style>
.hoverClass {
  z-index: 5 !important;
}
</style>
