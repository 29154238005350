const items = [
  {
    title: 'Implantação de Praças e Parques',
    folder: '1',
    count: 30
  },
  {
    title: 'Revitalização de Vias Públicas',
    folder: '2',
    count: 43
  },
  {
    title: 'Drenagem Urbana',
    folder: '3',
    count: 12
  },
  {
    title: 'Pavimentação Poliédrica',
    folder: '4',
    count: 26
  },
  {
    title: 'Pavimentação Paver',
    folder: '5',
    count: 14
  },
  {
    title: 'Pavimentação Asfáltica',
    folder: '6',
    count: 32
  },
  {
    title: 'Conservação de Rodovias',
    folder: '7',
    count: 14
  },
  {
    title: 'Implantação de Vias Urbanas',
    folder: '8',
    count: 18
  }
]

export default items
